import {
    Alert,
    Button,
    Card, Col, Collapse, DatePicker, DatePickerProps, Descriptions, Divider,
    Form, Image, Input, InputNumber,
    InputRef, message, Modal, notification, PageHeader, Popover, Radio, RadioChangeEvent, Row, Select, Space,
    Spin, Steps, StepsProps, Tabs, Timeline, Upload
} from "antd";

import useSafeState from "../../utils/safe-state";
import {useAxios} from "../../utils/hooks";
import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {
    CloseCircleOutlined,
    FileOutlined,
    InboxOutlined,
    LeftOutlined, PaperClipOutlined, PartitionOutlined,
    PlusOutlined,
    SaveOutlined,
    SmileOutlined,
    VerticalLeftOutlined
} from "@ant-design/icons/lib";
import {Trans} from "@lingui/macro";
import TextArea from "antd/es/input/TextArea";
import getEnumArray from "../../utils/enums";
import {AxiosResponse} from "axios";
import {antdContext} from "../../utils/antdContext";
import ReactJson from "react-json-view";
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import {SelectType} from "#/interfaces/SelectType";
import {useKeycloak} from "@react-keycloak/web";
import ClaimListScreen from "../../screens/claim-handling/claim-list";
import Drawer from "../../components/drawer/drawer";
import ClaimDrawer from "../../forms/claim-handling-forms/claim-drawer";
import diagram from "../../images/claim_handling_diagram.png";


interface Interface{
    requestId: any;
    mode?: "View" | "AddNew" | "Edit";
}

const TestForm = (props : Interface) => {

    const [form] = Form.useForm();

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const {initialized, keycloak} = useKeycloak();

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useSafeState(false);
    const [saveDataLoading, setSaveDataLoading] = useSafeState(false);
    const [nextStepLoading, setNextStepLoading] = useSafeState(false);
    const [back, setBack] = useSafeState<boolean>(false);
    const [disabled, setDisabled] = useSafeState<boolean>(false);
    const [closeBtnDisable, setCloseBtnDisable] = useSafeState<boolean>(true);


    const [userId, setUserId] = useSafeState<number>();
    const [isView, setIsView] = useSafeState<boolean>();
    const [handlingFaultyPartsOptions,sethandlingFaultyPartsOptions] = useSafeState<any>();
    const [inventoryControlOptions,setinventoryControlOptions] = useSafeState<any>();
    const [currentState,setCurrentState] = useSafeState<any>();
    const [needToCheck, setNeedToCheck] = useSafeState<boolean>(false);
    const [claimTypeOptions,setClaimTypeOptions] = useSafeState<any>();
    const [importanceRatingOptions,setImportanceRatingOptions] = useSafeState<any>();
    const [mainOptions,setMainOptions] = useSafeState<any>();
    const [demandedImmediateActionOptions,setDemandedImmediateActionOptions] = useSafeState<any>();
    const [responsibleOrganisationOptions,setResponsibleOrganisationOptions] = useSafeState<any>();
    const [spareIdentifierOptions,setSpareIdentifierOptions] = useSafeState<any>();

    const [isEmptyGoingOutAction2, setIsEmptyGoingOutAction2] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction3, setIsEmptyGoingOutAction3] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction4, setIsEmptyGoingOutAction4] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction5, setIsEmptyGoingOutAction5] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction6, setIsEmptyGoingOutAction6] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction7, setIsEmptyGoingOutAction7] = useSafeState<boolean>(true);

    const [isEmptyOccurenceErrorAction2, setIsEmptyOccurenceErrorAction2] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction3, setIsEmptyOccurenceErrorAction3] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction4, setIsEmptyOccurenceErrorAction4] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction5, setIsEmptyOccurenceErrorAction5] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction6, setIsEmptyOccurenceErrorAction6] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction7, setIsEmptyOccurenceErrorAction7] = useSafeState<boolean>(true);

    const goingOutAction2Change = (value: any) => { setIsEmptyGoingOutAction2(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction3Change = (value: any) => { setIsEmptyGoingOutAction3(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction4Change = (value: any) => { setIsEmptyGoingOutAction4(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction5Change = (value: any) => { setIsEmptyGoingOutAction5(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction6Change = (value: any) => { setIsEmptyGoingOutAction6(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction7Change = (value: any) => { setIsEmptyGoingOutAction7(!(value.target.value && value.target.value.length > 0)); }

    const occurenceErrorAction2Change = (value: any) => { setIsEmptyOccurenceErrorAction2(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction3Change = (value: any) => { setIsEmptyOccurenceErrorAction3(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction4Change = (value: any) => { setIsEmptyOccurenceErrorAction4(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction5Change = (value: any) => { setIsEmptyOccurenceErrorAction5(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction6Change = (value: any) => { setIsEmptyOccurenceErrorAction6(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction7Change = (value: any) => { setIsEmptyOccurenceErrorAction7(!(value.target.value && value.target.value.length > 0)); }

    const [itemNoSearch, setItemNoSearch] = useSafeState<any>();
    const [items, setItems] = useSafeState<any>([]);
    const [selectedItem, setSelectedItem] = useSafeState<any>();
    const [itemNo, setItemNo] = useSafeState<any>([]);
    const [isOtherEnable, setOtherEnable] = useSafeState<boolean>(false);
    const [errorMessages, setErrorMessages] = useSafeState<string[]>([]);
    const [errorMessagesVisible, setErrorMessagesVisible] = useSafeState<boolean>(true);

    const [isStartEnable, setIsStartEnable] = useSafeState<boolean>(false);
    const [isResponseEnable, setIsResponseEnable] = useSafeState<boolean>(false);
    const [isResponsibilityEnable, setIsResponsibilityEnable] = useSafeState<boolean>(false);
    const [isErrorReasonDataEnable, setIsErrorReasonDataEnable] = useSafeState<boolean>(false);
    const [isControlReceiverEnable, setIsControlReceiverEnable] = useSafeState<boolean>(false);
    const [isApprovalReceiverEnable, setIsApprovalReceiverEnable] = useSafeState<boolean>(false);
    const [isAcceptanceEnable, setIsAcceptanceEnable] = useSafeState<boolean>(false);
    const [isControlSenderEnable, setIsControlSenderEnable] = useSafeState<boolean>(false);
    const [isApprovalSenderEnable, setApprovalSenderEnable] = useSafeState<boolean>(false);
    const [isAnyError1Enable, setIsAnyError1Enable] = useSafeState<boolean>(false);
    const [isAnyError2Enable, setIsAnyError2Enable] = useSafeState<boolean>(false);
    const [isAnyError3Enable, setIsAnyError3Enable] = useSafeState<boolean>(false);

    const text = <Trans>Claim handling uploaded files list</Trans>

    const fileDrawer = Drawer(
        {
            title: text,
            children:
                <ClaimDrawer/>
        }
    );

    let nowDate = moment();

    const inputRef = useRef<InputRef>(null);
    const { Step } = Steps;

    const { Panel } = Collapse;

    const sharedProps = {
        ref: inputRef,
    };

    const groupName2Change = (value: any) => { setIsEmptyGroupName2(!(value.target.value && value.target.value.length > 0)); }
    const groupName3Change = (value: any) => { setIsEmptyGroupName3(!(value.target.value && value.target.value.length > 0)); }
    const groupName4Change = (value: any) => { setIsEmptyGroupName4(!(value.target.value && value.target.value.length > 0)); }
    const groupName5Change = (value: any) => { setIsEmptyGroupName5(!(value.target.value && value.target.value.length > 0)); }
    const groupName6Change = (value: any) => { setIsEmptyGroupName6(!(value.target.value && value.target.value.length > 0)); }
    const groupName7Change = (value: any) => { setIsEmptyGroupName7(!(value.target.value && value.target.value.length > 0)); }

    const [isEmptyGroupName2, setIsEmptyGroupName2] = useSafeState<boolean>(true);
    const [isEmptyGroupName3, setIsEmptyGroupName3] = useSafeState<boolean>(true);
    const [isEmptyGroupName4, setIsEmptyGroupName4] = useSafeState<boolean>(true);
    const [isEmptyGroupName5, setIsEmptyGroupName5] = useSafeState<boolean>(true);
    const [isEmptyGroupName6, setIsEmptyGroupName6] = useSafeState<boolean>(true);
    const [isEmptyGroupName7, setIsEmptyGroupName7] = useSafeState<boolean>(true);

    const [disabledJustification, setDisabledJustification] = useSafeState(true);
    const [justificationRequired, setJustificationRequired] = useSafeState(false);

    const [value, setValue] = useState(1);
    const [userOptions,setUserOptions] = useSafeState<any>();

    const [organizationOptions,setOrganizationOptions] = useSafeState<any>();
    const [supplierOptions,setSupplierOptions] = useSafeState<any>();

    const [infoData, setInfoData] = useSafeState<any>();

    const { Dragger } = Upload;

    type optionalType = {
        key?: number;
        label: any;
        value: string;
    };


    const fillCurrentState = (state: any) => {

        switch(state){
            case 'Start':
                setIsStartEnable(true);
                setCurrentState(0);
                break;
            case 'Response':
                setIsResponseEnable(true);
                setCurrentState(1);
                break;
            case 'Responsibility':
                setIsResponsibilityEnable(true);
                setCurrentState(2);
                break;
            case 'Error reason data':
                setIsErrorReasonDataEnable(true);
                setCurrentState(2);
                break;
            case 'Control (Receiver)':
                setIsControlReceiverEnable(true);
                setCurrentState(3);
                break;
            case 'Approval (Receiver)':
                setIsApprovalReceiverEnable(true);
                setCurrentState(4);
                break;
            case 'Acceptance':
                setIsAcceptanceEnable(true);
                setCurrentState(5);
                break;
            case 'Control (Sender)':
                setIsControlSenderEnable(true);
                setCurrentState(6);
                break;
            case 'Approval (Sender)':
                setApprovalSenderEnable(true);
                setCurrentState(7);
                break;
            case 'Any Error? 1.':
                setIsAnyError1Enable(true);
                setCurrentState(9);
                break;
            case 'Any Error? 2.':
                setIsAnyError2Enable(true);
                setCurrentState(10);
                break;
            case 'Any Error? 3.':
                setIsAnyError3Enable(true);
                setCurrentState(11);
                break;
            case 'Closing':
            case 'Closed':
                setCloseBtnDisable(false);
                setCurrentState(12);
                break;
        }
    }

    const viewMode = () => {
        if(props.requestId == undefined){
            setIsStartEnable(true);
        }

        if(axiosInstance.current != null && props.requestId != undefined) {

            axiosInstance
                .current
                .get("/resource/request-data/get-request-data?requestId=" + props.requestId, {})
                .then(function (response : AxiosResponse<any>) {

                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));

                    dataArray
                        .forEach(value => {
                        switch (value.name) {
                            case 'initData.regNum':
                                form.setFieldsValue({"initData.regNum":value.value});
                                break;
                            case 'initData.refNum':
                                form.setFieldsValue({"initData.refNum":value.value});
                                break;
                            case 'initData.originOfThePart':
                                value.value == 'Internal list' ? form.setFieldsValue({"initData.originOfThePart":1}) : form.setFieldsValue({"initData.originOfThePart":2});
                                break;
                            case 'initData.responsibleDepartment':
                                form.setFieldsValue({"initData.responsibleDepartment":value.value});
                                break;
                            case 'initData.dateOfRelease':
                                const dateOfRelease = new Date(value.value);
                                const momentOfRelease = moment(dateOfRelease);
                                form.setFieldsValue({"initData.dateOfRelease":momentOfRelease});
                                break;
                            case 'initData.state':
                                form.setFieldsValue({"initData.state":value.value});
                                fillCurrentState(value.value);
                                break;
                            case 'initData.immediateActionDeadline':
                                const immediateactionDate = new Date(value.value);
                                const immediateactionMoment = moment(immediateactionDate);
                                form.setFieldsValue({"initData.immediateActionDeadline":immediateactionMoment});
                                break;
                            case 'initData.finallyActionDeadline':
                                const finalactionDate = new Date(value.value);
                                const finalactionMoment = moment(finalactionDate);
                                form.setFieldsValue({"initData.finallyActionDeadline":finalactionMoment});
                                break;
                            case 'initData.releaser':
                                form.setFieldsValue({"initData.releaser":value.value});
                                break;
                            case 'initData.controlPerson':
                                form.setFieldsValue({"initData.controlPerson":value.value});
                                break;
                            case 'initData.approvalPerson':
                                form.setFieldsValue({"initData.approvalPerson":value.value});
                                break;
                            case 'initData.otherStakeholder':
                                form.setFieldsValue({"initData.otherStakeholder":value.value});
                                break;
                            case 'initData.remark':
                                form.setFieldsValue({"initData.remark":value.value});
                                break;
                            case 'initData.claimType':
                                form.setFieldsValue({"initData.claimType":value.value});
                                break;
                            case 'initData.priority':
                                form.setFieldsValue({"initData.priority":value.value});
                                break;
                            case 'initData.partNumber':
                                form.setFieldsValue({"initData.partNumber":value.value});
                                break;
                            case 'initData.partName':
                                form.setFieldsValue({"initData.partName":value.value});
                                break;
                            case 'initData.location':
                                form.setFieldsValue({"initData.location":value.value});
                                break;
                            case 'initData.findingDate':
                                const dt = new Date(value.value);
                                const dtMoment = moment(dt);
                                form.setFieldsValue({"initData.findingDate": dtMoment});
                                break;
                            case 'initData.signOfNextItem':
                                value.value == 'Needed' ? form.setFieldsValue({"initData.signOfNextItem":1}) : form.setFieldsValue({"initData.signOfNextItem":2});
                                break;
                            case 'initData.immediateActions':
                                form.setFieldsValue({"initData.immediateActions": value.value});
                                break;
                            case 'initData.nameOfError':
                                form.setFieldsValue({"initData.nameOfError":value.value});
                                break;
                            case 'initData.qtyOfWrong':
                                form.setFieldsValue({"initData.qtyOfWrong":value.value});
                                break;
                            case 'initData.qtyOfFinallyWrong':
                                form.setFieldsValue({"initData.qtyOfFinallyWrong":value.value});
                                break;
                            case 'initData.descriptionOfError':
                                form.setFieldsValue({"initData.descriptionOfError":value.value});
                                break;

                            case 'repairData.team.name1':
                                form.setFieldsValue({"repairData.team.name1": value.value});
                                break;
                            case 'repairData.team.email1':
                                form.setFieldsValue({"repairData.team.email1": value.value});
                                break;
                            case 'repairData.team.rank1':
                                form.setFieldsValue({"repairData.team.rank1":value.value});
                                break;

                            case 'repairData.team.name2':
                                form.setFieldsValue({"repairData.team.name2":value.value});
                                break;
                            case 'repairData.team.email2':
                                form.setFieldsValue({"repairData.team.email2":value.value});
                                break;
                            case 'repairData.team.rank2':
                                form.setFieldsValue({"repairData.team.rank2":value.value});
                                break;

                            case 'repairData.team.name3':
                                form.setFieldsValue({"repairData.team.name3":value.value});
                                break;
                            case 'repairData.team.email3':
                                form.setFieldsValue({"repairData.team.email3":value.value});
                                break;
                            case 'repairData.team.rank3':
                                form.setFieldsValue({"repairData.team.rank3":value.value});
                                break;

                            case 'repairData.team.name4':
                                form.setFieldsValue({"repairData.team.name4":value.value});
                                break;
                            case 'repairData.team.email4':
                                form.setFieldsValue({"repairData.team.email4":value.value});
                                break;
                            case 'repairData.team.rank4':
                                form.setFieldsValue({"repairData.team.rank4":value.value});
                                break;

                            case 'repairData.team.name5':
                                form.setFieldsValue({"repairData.team.name5":value.value});
                                break;
                            case 'repairData.team.email5':
                                form.setFieldsValue({"repairData.team.email5":value.value});
                                break;
                            case 'repairData.team.rank5':
                                form.setFieldsValue({"repairData.team.rank5":value.value});
                                break;

                            case 'repairData.team.name6':
                                form.setFieldsValue({"repairData.team.name6":value.value});
                                break;
                            case 'repairData.team.email6':
                                form.setFieldsValue({"repairData.team.email6":value.value});
                                break;
                            case 'repairData.team.rank6':
                                form.setFieldsValue({"repairData.team.rank6":value.value});
                                break;

                            case 'repairData.team.name7':
                                form.setFieldsValue({"repairData.team.name7":value.value});
                                break;
                            case 'repairData.team.email7':
                                form.setFieldsValue({"repairData.team.email7":value.value});
                                break;
                            case 'repairData.team.rank7':
                                form.setFieldsValue({"repairData.team.rank7":value.value});
                                break;

                            case 'repairData.problem.description':
                                form.setFieldsValue({"repairData.problem.description":value.value});
                                break;
                            case 'repairData.problem.name':
                                form.setFieldsValue({"repairData.problem.name":value.value});
                                break;
                            case 'repairData.productionData.what':
                                form.setFieldsValue({"repairData.productionData.what":value.value});
                                break;
                            case 'repairData.productionData.when':
                                form.setFieldsValue({"repairData.productionData.when":value.value});
                                break;
                            case 'repairData.productionData.where':
                                form.setFieldsValue({"repairData.productionData.where":value.value});
                                break;
                            case 'repairData.productionData.who':
                                form.setFieldsValue({"repairData.productionData.who":value.value});
                                break;


                            case 'repairData.immediateActionData.deadline':
                                const deadline = new Date(value.value);
                                const mom = moment(deadline);
                                form.setFieldsValue({"repairData.immediateActionData.deadline": mom});
                                break;
                            case 'repairData.immediateActionData.handlingOfFoundedPartsAtMtsk':
                                form.setFieldsValue({"repairData.immediateActionData.handlingOfFoundedPartsAtMtsk":value.value});
                                break;
                            case 'repairData.immediateActionData.repairAction':
                                form.setFieldsValue({"repairData.immediateActionData.repairAction":value.value});
                                break;
                            case 'repairData.immediateActionData.responsible':
                                form.setFieldsValue({"repairData.immediateActionData.responsible":value.value});
                                break;


                            case 'repairData.stockCheckResult.qtyOfOk':
                                form.setFieldsValue({"repairData.stockCheckResult.qtyOfOk":value.value});
                                break;
                            case 'repairData.stockCheckResult.qtyOfNg':
                                form.setFieldsValue({"repairData.stockCheckResult.qtyOfNg":value.value});
                                break;
                            case 'repairData.stockCheckResult.qtyOfRepaired':
                                form.setFieldsValue({"repairData.stockCheckResult.qtyOfRepaired":value.value});
                                break;
                            case 'repairData.stockCheckResult.qtyOfSum':
                                form.setFieldsValue({"repairData.stockCheckResult.qtyOfSum":value.value});
                                break;


                            case 'responsibility':
                                value.value == 'APPROVE' ? form.setFieldsValue({"responsibility":1}) : form.setFieldsValue({"responsibility":2});
                                break;
                            case 'responsibilityRemark':
                                form.setFieldsValue({"responsibilityRemark":value.value});
                                break;

                            case 'resultOfAcceptance':
                                value.value == 'APPROVE' ? form.setFieldsValue({"resultOfAcceptance":1}) : form.setFieldsValue({"resultOfAcceptance":2});
                                break;

                            case 'resultOfApprovalReceiver':
                                value.value == 'APPROVE' ? form.setFieldsValue({"resultOfApprovalReceiver":1}) : form.setFieldsValue({"resultOfApprovalReceiver":2});
                                break;

                            case 'resultOfApprovalSender':
                                value.value == 'APPROVE' ? form.setFieldsValue({"resultOfApprovalSender":1}) : form.setFieldsValue({"resultOfApprovalSender":2});
                                break;

                            case 'resultOfControlReceiver':
                                value.value == 'APPROVE' ? form.setFieldsValue({"resultOfControlReceiver":1}) : form.setFieldsValue({"resultOfControlReceiver":2});
                                break;

                            case 'resultOfControlSender':
                                value.value == 'APPROVE' ? form.setFieldsValue({"resultOfControlSender":1}) : form.setFieldsValue({"resultOfControlSender":2});
                                break;

                            case 'fiveWhyAnalisysOfErrorCreation.why1':
                                form.setFieldsValue({"fiveWhyAnalisysOfErrorCreation.why1":value.value});
                                break;
                            case 'fiveWhyAnalisysOfErrorCreation.why2':
                                form.setFieldsValue({"fiveWhyAnalisysOfErrorCreation.why2":value.value});
                                break;
                            case 'fiveWhyAnalisysOfErrorCreation.why3':
                                form.setFieldsValue({"fiveWhyAnalisysOfErrorCreation.why3":value.value});
                                break;
                            case 'fiveWhyAnalisysOfErrorCreation.why4':
                                form.setFieldsValue({"fiveWhyAnalisysOfErrorCreation.why4":value.value});
                                break;
                            case 'fiveWhyAnalisysOfErrorCreation.why5':
                                form.setFieldsValue({"fiveWhyAnalisysOfErrorCreation.why5":value.value});
                                break;
                            case 'fiveWhyAnalisysOfErrorCreation.rootCauseOfErrorCreation':
                                form.setFieldsValue({"fiveWhyAnalisysOfErrorCreation.rootCauseOfErrorCreation":value.value});
                                break;
                            case 'fiveWhyAnalisysOfGoingOut.why1':
                                form.setFieldsValue({"fiveWhyAnalisysOfGoingOut.why1":value.value});
                                break;
                            case 'fiveWhyAnalisysOfGoingOut.why2':
                                form.setFieldsValue({"fiveWhyAnalisysOfGoingOut.why2":value.value});
                                break;
                            case 'fiveWhyAnalisysOfGoingOut.why3':
                                form.setFieldsValue({"fiveWhyAnalisysOfGoingOut.why3":value.value});
                                break;
                            case 'fiveWhyAnalisysOfGoingOut.why4':
                                form.setFieldsValue({"fiveWhyAnalisysOfGoingOut.why4":value.value});
                                break;
                            case 'fiveWhyAnalisysOfGoingOut.why5':
                                form.setFieldsValue({"fiveWhyAnalisysOfGoingOut.why5":value.value});
                                break;
                            case 'fiveWhyAnalisysOfGoingOut.rootCauseOfGoingOut':
                                form.setFieldsValue({"fiveWhyAnalisysOfGoingOut.rootCauseOfGoingOut":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.action1':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.action1":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.responsible1':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.responsible1":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.deadline1':
                                const deadline1 = new Date(value.value);
                                const mom1 = moment(deadline1);
                                form.setFieldsValue({"actionsToTerminateCreationOfError.deadline1": mom1});
                                break;
                            case 'actionsToTerminateCreationOfError.action2':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.action2":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.responsible2':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.responsible2":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.deadline2':
                                const deadline2 = new Date(value.value);
                                const mom2 = moment(deadline2);
                                form.setFieldsValue({"actionsToTerminateCreationOfError.deadline2": mom2});
                                break;
                            case 'actionsToTerminateCreationOfError.action3':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.action3":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.responsible3':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.responsible3":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.deadline3':
                                const deadline3 = new Date(value.value);
                                const mom3 = moment(deadline3);
                                form.setFieldsValue({"actionsToTerminateCreationOfError.deadline3": mom3});
                                break;
                            case 'actionsToTerminateCreationOfError.action4':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.action4":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.responsible4':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.responsible4":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.deadline4':
                                const deadline4 = new Date(value.value);
                                const mom4 = moment(deadline4);
                                form.setFieldsValue({"actionsToTerminateCreationOfError.deadline4": mom4});
                                break;
                            case 'actionsToTerminateCreationOfError.action5':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.action5":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.responsible5':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.responsible5":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.deadline5':
                                const deadline5 = new Date(value.value);
                                const mom5 = moment(deadline5);
                                form.setFieldsValue({"actionsToTerminateCreationOfError.deadline5": mom5});
                                break;
                            case 'actionsToTerminateCreationOfError.action6':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.action6":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.responsible6':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.responsible6":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.deadline6':
                                const deadline6 = new Date(value.value);
                                const mom6 = moment(deadline6);
                                form.setFieldsValue({"actionsToTerminateCreationOfError.deadline6": mom6});
                                break;
                            case 'actionsToTerminateCreationOfError.action7':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.action7":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.responsible7':
                                form.setFieldsValue({"actionsToTerminateCreationOfError.responsible7":value.value});
                                break;
                            case 'actionsToTerminateCreationOfError.deadline7':
                                const deadline7 = new Date(value.value);
                                const mom7 = moment(deadline7);
                                form.setFieldsValue({"actionsToTerminateCreationOfError.deadline7": mom7});
                                break;
                            case 'actionsToTerminateGoingOutOfError.action1':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.action1":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.responsible1':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.responsible1":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.deadline1':
                                const dl1 = new Date(value.value);
                                const mm1 = moment(dl1);
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.deadline1": mm1});
                                break;
                            case 'actionsToTerminateGoingOutOfError.action2':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.action2":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.responsible2':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.responsible2":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.deadline2':
                                const dl2 = new Date(value.value);
                                const mm2 = moment(dl2);
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.deadline2": mm2});
                                break;
                            case 'actionsToTerminateGoingOutOfError.action3':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.action3":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.responsible3':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.responsible3":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.deadline3':
                                const dl3 = new Date(value.value);
                                const mm3 = moment(dl3);
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.deadline3": mm3});
                                break;
                            case 'actionsToTerminateGoingOutOfError.action4':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.action4":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.responsible4':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.responsible4":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.deadline4':
                                const dl4 = new Date(value.value);
                                const mm4 = moment(dl4);
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.deadline4": mm4});
                                break;
                            case 'actionsToTerminateGoingOutOfError.action5':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.action5":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.responsible5':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.responsible5":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.deadline5':
                                const dl5 = new Date(value.value);
                                const mm5 = moment(dl5);
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.deadline5": mm5});
                                break;
                            case 'actionsToTerminateGoingOutOfError.action6':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.action6":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.responsible6':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.responsible6":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.deadline6':
                                const dl6 = new Date(value.value);
                                const mm6 = moment(dl6);
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.deadline6": mm6});
                                break;
                            case 'actionsToTerminateGoingOutOfError.action7':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.action7":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.responsible7':
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.responsible7":value.value});
                                break;
                            case 'actionsToTerminateGoingOutOfError.deadline7':
                                const dl7 = new Date(value.value);
                                const mm7 = moment(dl7);
                                form.setFieldsValue({"actionsToTerminateGoingOutOfError.deadline7": mm7});
                                break;

                            default:
                                break;
                        }

                    });

                }).catch(function (error :any) {
                    message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                    console.log(error)
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        form.setFieldsValue({'initData.dateOfRelease':nowDate});
        form.setFieldsValue({'initData.immediateActionDeadline':nowDate});
        form.setFieldsValue({'initData.releaser': localStorage.getItem('email') });
        setResponsibleOrganisationOptions(getEnumArray('responsibleOrganisations'));
        setClaimTypeOptions(getEnumArray('claimType'));
        setImportanceRatingOptions(getEnumArray('importanceRating'));
        setSpareIdentifierOptions(getEnumArray('spareIdentifier'));
        setDemandedImmediateActionOptions(getEnumArray('demandedImmediateAction'));

        setinventoryControlOptions(getEnumArray('inventorycontrol'));
        sethandlingFaultyPartsOptions(getEnumArray('handlingfaultyparts'));
        needToCheckStock(props.requestId);

        setDisabled(props.requestId != undefined);
        setIsView(props.mode == 'View' ? true : false);
        if (props.mode == 'Edit' || props.mode == 'View') {
            viewMode();
        }else{
            setIsStartEnable(props.requestId == undefined);
        }

        if (!isView) {
            getUserId();
        }
        getOrganization();
        getSupplier();
        getClaimHandlingControllerUser();
        getClaimHandlingApproverUser();
        getUsers();
    }, []);

    const getUsers = () => {

        if(axiosInstance.current != null) {

            axiosInstance.current.get('/resource/user')
                .then(function (response: AxiosResponse<any>) {
                    if (Array.isArray(response.data)) {
                        let userArray: Array<SelectType> = [];
                        response.data.forEach(value => {
                            userArray.push(
                                {
                                    label: value.email,
                                    value: value.email,
                                }
                            )
                        });
                        setUserOptions(userArray);
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getOrganization = () => {

        if(axiosInstance.current != null) {
            axiosInstance.current.get('/resource/settings/organizations')
                .then(function (response: AxiosResponse<any>) {
                    let res: Array<any> = [];

                    Object.entries(response.data).forEach(([key, value]) => {
                        res.push(
                            {
                                label: key,
                                value: value,
                            }
                        )
                    });
                    setOrganizationOptions(res);
                    setMainOptions(res);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const generateOptions = (data: any, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { key: i, value: data[i]['id'][val], label: data[i]['id'][val] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const getClaimHandlingControllerUser = () => {

        if(axiosInstance.current != null) {
            axiosInstance.current.get('/resource/settings/claim-handling-controller-user')
                .then(function (response: AxiosResponse<any>) {
                    form.setFieldsValue({"initData.controlPerson": response.data});
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getClaimHandlingApproverUser = () => {

        if(axiosInstance.current != null) {
            axiosInstance.current.get('/resource/settings/claim-handling-approver-user')
                .then(function (response: AxiosResponse<any>) {
                    form.setFieldsValue({"initData.approvalPerson": response.data});
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getSupplier = () => {

        if(axiosInstance.current != null) {

            axiosInstance.current.get('/resource/suppliers')
                .then(function (response: AxiosResponse<any>) {
                    if (Array.isArray(response.data)) {
                        let supplierArray: Array<SelectType> = [];
                        response.data.forEach(value => {

                            const label = value.code;

                            supplierArray.push(
                                {
                                    label: label,
                                    value: label,
                                }
                            )
                        });
                        setSupplierOptions(supplierArray);
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getItems = (itemNo: string) => {

        if (itemNo) {
            if (axiosInstance.current != null) {

                let queryJson: string = '{';
                queryJson += itemNo ? '"id": { "itemNo": "' + itemNo + '"} , ' : '';
                queryJson += '}';

                if (queryJson.length > 2) {
                    queryJson =
                        queryJson.substr(0, queryJson.length - 3) +
                        queryJson.substr(queryJson.length - 2, queryJson.length);
                }

                axiosInstance.current
                    .post('/resource/items/page', {
                        start: 0,
                        length: 20,
                        direction: 'asc',
                        sorted_field: 'id.itemNo',
                        query: JSON.parse(queryJson),
                    })
                    .then(function (response: AxiosResponse<any>) {
                        setItems(response.data.data);
                        setItemNo(generateOptions(response.data.data, 'itemNo'));
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };

    const getUserId = () => {
        if(axiosInstance.current != null) {

            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            axiosInstance.current.get("/resource/user/get-user?userName=" + obj.preferred_username, {})
                .then(function (response : AxiosResponse<any>) {
                    let data: any = JSON.parse(JSON.stringify(response.data));
                    setUserId(data.id);
                })
                .catch(function (error :any) {
                    message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                    console.log(error)
                });
        }
    }

    useEffect(() => {
        getItems(itemNoSearch);
    }, [itemNoSearch]);

    const handleDemandActionChange = (value: any) => {

        if(value == 'OTHER'){
            setOtherEnable(true);
        } else {
            setOtherEnable(false);
        }
    }

    const handleOutDeviceToOnChange = (value: any) => {

        let actItem: any;
        for (var i = 0; i < items.length; i++) {
            if (items[i]['id']['itemNo'] == value) {
                actItem = items[i];
                setSelectedItem(actItem);
                break;
            }
        }
        console.log(actItem);
        if (actItem) {
            //form.setFieldsValue({ initData: { partNo: actItem['id']['itemNo'] }});
            form.setFieldsValue({"initData.partName": actItem['nameHun']});
            if (mainOptions == supplierOptions) {
                form.setFieldsValue({ responsibleorganisation: actItem['id']['supplier']['code'] });
            }
        }
    };

    const needToCheckStock = (id: number) => {

        if(axiosInstance.current != null && id != undefined){

            axiosInstance
                .current
                .get(process.env.REACT_APP_API_BASE_URL + '/resource/request-data/need-to-check-stock-by-request-id?requestId=' + id)
                .then(function (response: AxiosResponse<any>) {
                    setNeedToCheck(response.data);
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
                console.log(error);
            }).finally(() => {

            });
        }
    };

    const responsibilityOnChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const partSourceOnChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        form.setFieldsValue({ responsibleorganisation: ''});
        setMainOptions(form.getFieldValue(["partsource"]) == 1 ? organizationOptions : supplierOptions);
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        fileList,
    };

    const handleUpload = (requestId:any) => {

        fileList.forEach((file) => {
            const uploadedFile = new FormData();
            uploadedFile.append('uploadedFile', file as RcFile);
            if (axiosInstance.current != null) {

                axiosInstance.current.post("/resource/request-files/upload-file?requestId=" + requestId + "&actionId=1", uploadedFile,
                    { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                    setUploading(false);
                }).catch(function (error :any) {
                    console.log(error)
                    setUploading(false);
                });
            }
        });
        setUploading(true);
    };

    const nextItemMarkOnChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const wrongQuantityOnChange = (value: number) => {
        form.setFieldsValue({'wrongquantity':value});
    };

    const finallyWrongQuantityOnChange = (value: number) => {
        form.setFieldsValue({'finallywrongquantity':value});
    };

    const customValidate = (fields : string[]) : boolean => {
        setErrorMessages([]);

        let ret = true;
        let retArr : string[] = [];
        for( let i = 0; i < fields.length; i++ ){
            if(form.getFieldValue(fields[i]) == undefined){
                retArr.push(fields[i]);
                ret = false;
            }
        }

        if(ret == false){
            setErrorMessages(retArr);
        }
        return ret;
    }

    const validate = (saveWithNextStep: boolean) => {

        let initDataArray : string[] = [
            'initData.regNum',
            'initData.refNum',
            'initData.claimType',
            'initData.dateOfRelease',
            'initData.immediateActionDeadline',
            'initData.finallyActionDeadline',
            'initData.approvalPerson',
            'initData.controlPerson',
            'initData.responsibleDepartment',
            'initData.findingDate',
            'initData.immediateActions',
            'initData.location',
            'initData.nameOfError',
            'initData.originOfThePart',
            'initData.priority',
            'initData.partName',
            'initData.partNumber',
            'initData.qtyOfWrong',
            'initData.signOfNextItem'
        ];

        let responseArray : string[] = [
            'repairData.team.name1',
            'repairData.team.email1',
            'repairData.team.rank1',

            'repairData.problem.name',
            'repairData.problem.description',

            'repairData.productionData.who',
            'repairData.productionData.where',
            'repairData.productionData.what',
            'repairData.productionData.when',

            'repairData.immediateActionData.repairAction',
            'repairData.immediateActionData.responsible',
            'repairData.immediateActionData.deadline',
            'repairData.immediateActionData.handlingOfFoundedPartsAtMtsk'
        ];

        let responsibilityArray : string[] = [
            'responsibility'
        ];

        let errorReasonDataArray : string[] = [
            'fiveWhyAnalisysOfErrorCreation.why1',
            'fiveWhyAnalisysOfErrorCreation.why2',
            'fiveWhyAnalisysOfErrorCreation.why3',
            'fiveWhyAnalisysOfErrorCreation.why4',
            'fiveWhyAnalisysOfErrorCreation.why5',
            'fiveWhyAnalisysOfErrorCreation.rootCauseOfErrorCreation',
            'fiveWhyAnalisysOfGoingOut.why1',
            'fiveWhyAnalisysOfGoingOut.why2',
            'fiveWhyAnalisysOfGoingOut.why3',
            'fiveWhyAnalisysOfGoingOut.why4',
            'fiveWhyAnalisysOfGoingOut.why5',
            'fiveWhyAnalisysOfGoingOut.rootCauseOfGoingOut',
            'actionsToTerminateCreationOfError.action1',
            'actionsToTerminateCreationOfError.responsible1',
            'actionsToTerminateCreationOfError.deadline1',
            'actionsToTerminateGoingOutOfError.action1',
            'actionsToTerminateGoingOutOfError.responsible1',
            'actionsToTerminateGoingOutOfError.deadline1',
        ];

        let controlReceiverArray : string[] = ['resultOfControlReceiver'];

        let approvalReceiverArray : string[] = ['resultOfApprovalReceiver'];
        let acceptanceArray : string[] = ['resultOfAcceptance'];
        let controlSenderArray : string[] = ['resultOfControlSender'];
        let approvalSenderArray : string[] = ['resultOfApprovalSender'];
        let anyError1Array : string[] = ['resultOfAnyError1'];
        let anyError2Array : string[] = ['resultOfAnyError2'];
        let anyError3Array : string[] = ['resultOfAnyError3'];

        if(props.requestId == undefined){
            console.log('ITTTT');
            form.validateFields(initDataArray).finally(() => {
                if(customValidate(initDataArray)) {
                    save(saveWithNextStep);
                }
            });
        } else {

            switch(form.getFieldValue("initData.state")){
                case 'Start':
                    form.validateFields(initDataArray).finally(() => {
                        if(customValidate(initDataArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Response':
                    form.validateFields(responseArray).finally(() => {
                        if(customValidate(responseArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Responsibility':
                    form.validateFields(responsibilityArray).finally(() => {
                        if(customValidate(responsibilityArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Error reason data':
                    form.validateFields(errorReasonDataArray).finally(() => {
                        if(customValidate(errorReasonDataArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Control (Receiver)':
                    form.validateFields(controlReceiverArray).finally(() => {
                        if(customValidate(controlReceiverArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Approval (Receiver)':
                    form.validateFields(approvalReceiverArray).finally(() => {
                        if(customValidate(approvalReceiverArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Acceptance':
                    form.validateFields(acceptanceArray).finally(() => {
                        if(customValidate(acceptanceArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Control (Sender)':
                    form.validateFields(controlSenderArray).finally(() => {
                        if(customValidate(controlSenderArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Approval (Sender)':
                    form.validateFields(approvalSenderArray).finally(() => {
                        if(customValidate(approvalSenderArray)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Any Error? 1.':
                    form.validateFields(anyError1Array).finally(() => {
                        if(customValidate(anyError1Array)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Any Error? 2.':
                    form.validateFields(anyError2Array).finally(() => {
                        if(customValidate(anyError2Array)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Any Error? 3.':
                    form.validateFields(anyError3Array).finally(() => {
                        if(customValidate(anyError3Array)) {
                            save(saveWithNextStep);
                        }
                    });
                    break;
                case 'Closing':
                case 'Closed':
                    break;
            }
        }
    }

    const closeClaim = (requestId: any) => {

        if(axiosInstance.current != null){

            axiosInstance.current
                .get(process.env.REACT_APP_API_BASE_URL + '/resource/requests/i-am-the-next?requestId=' + requestId)
                .then(function (response: AxiosResponse<any>) {

                    if(response.data == true){

                        if(axiosInstance.current != null) {

                            axiosInstance.current.post("/resource/requests/complete-by-name-and-action-type?requestId=" + requestId + "&stateName=" + "Closing" + "&actionType=APPROVE",
                                {}
                            ).then(() => {
                                message.success(antdContext('Sikeres lezárás'));
                                setBack(true);
                            }).catch(function (error :any) {
                                message.error(antdContext('Sikertelen lezárás!')).then(r => {console.log(r)});
                                console.log(error)
                            });
                        }

                    } else {
                        message.error(antdContext('Nincs jogosultságod lezárni!'));
                    }
                })
                .catch(function (error :any) {
                    message.error(antdContext('Nincs jogosultságod lezárni!'));
                });
        }
    }

    const save = (saveWithNextStep: boolean) => {

        var obj = {};
        if(props.requestId == undefined){

            obj = {
                initData: {
                    approvalPerson: form.getFieldValue(["initData.approvalPerson"]),
                    claimType: form.getFieldValue(["initData.claimType"]),
                    controlPerson: form.getFieldValue(["initData.controlPerson"]),
                    dateOfRelease: moment(form.getFieldValue(["initData.dateOfRelease"])).format('YYYY-MM-DD'),
                    descriptionOfError: form.getFieldValue(["initData.descriptionOfError"]),
                    finallyActionDeadline: moment(form.getFieldValue(["initData.finallyActionDeadline"])).format('YYYY-MM-DD'),
                    findingDate: moment(form.getFieldValue(["initData.findingDate"])).format('YYYY-MM-DD'),
                    immediateActionDeadline: moment(form.getFieldValue(["initData.immediateActionDeadline"])).format('YYYY-MM-DD'),
                    immediateActions: form.getFieldValue(["initData.immediateActions"]),
                    location: form.getFieldValue(["initData.location"]),
                    nameOfError: form.getFieldValue(["initData.nameOfError"]),
                    originOfThePart: form.getFieldValue(["initData.originOfThePart"]) == 1 ? "Internal list" : "Supplier list",
                    otherAction: form.getFieldValue(["initData.otherAction"]),
                    otherStakeholder: form.getFieldValue(["initData.otherStakeholder"]),
                    partName: form.getFieldValue(["initData.partName"]),
                    partNumber: form.getFieldValue(["initData.partNumber"]),
                    priority: form.getFieldValue(["initData.priority"]),
                    qtyOfWrong: form.getFieldValue(["initData.qtyOfWrong"]),
                    refNum: form.getFieldValue(["initData.refNum"]),
                    regNum: form.getFieldValue(["initData.regNum"]),
                    releaser: form.getFieldValue(["initData.releaser"]),
                    remark: form.getFieldValue(["initData.remark"]),
                    responsibleDepartment: form.getFieldValue(["initData.responsibleDepartment"]),
                    signOfNextItem: form.getFieldValue(["initData.signOfNextItem"]) == 1 ? "Needed" : "Not needed",
                    state: form.getFieldValue(["initData.state"])
                }
            };

            if(axiosInstance.current != null){

                if(!saveWithNextStep){
                    setSaveDataLoading(true);
                }

                axiosInstance.current
                    .post(
                        "/resource/requests/claim-handling/start",
                        obj
                    )
                    .then(function (response : AxiosResponse<any>) {

                        if (fileList.length > 0) {
                            handleUpload(response.data.id);
                        }

                        if(saveWithNextStep){
                            nextStepMain(response.data.id, response.data.currentState.name);
                        }else{
                            message.success(antdContext('Sikeres mentés'));
                        }

                    })
                    .catch(function (error :any) {
                        message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                        console.log(error)
                    })
                    .finally(() => {
                        if(!saveWithNextStep){
                            setSaveDataLoading(false);
                        }
                    });
            }
        } else {
            switch(form.getFieldValue("initData.state")){
                case 'Start':
                    obj = {
                        initData: {
                            approvalPerson: form.getFieldValue(["initData.approvalPerson"]),
                            claimType: form.getFieldValue(["initData.claimType"]),
                            controlPerson: form.getFieldValue(["initData.controlPerson"]),
                            dateOfRelease: moment(form.getFieldValue(["initData.dateOfRelease"])).format('YYYY-MM-DD'),
                            descriptionOfError: form.getFieldValue(["initData.descriptionOfError"]),
                            finallyActionDeadline: moment(form.getFieldValue(["initData.finallyActionDeadline"])).format('YYYY-MM-DD'),
                            findingDate: moment(form.getFieldValue(["initData.findingDate"])).format('YYYY-MM-DD'),
                            immediateActionDeadline: moment(form.getFieldValue(["initData.immediateActionDeadline"])).format('YYYY-MM-DD'),
                            immediateActions: form.getFieldValue(["initData.immediateActions"]),
                            location: form.getFieldValue(["initData.location"]),
                            nameOfError: form.getFieldValue(["initData.nameOfError"]),
                            originOfThePart: form.getFieldValue(["initData.originOfThePart"]) == 1 ? "Internal list" : "Supplier list",
                            otherAction: form.getFieldValue(["initData.otherAction"]),
                            otherStakeholder: form.getFieldValue(["initData.otherStakeholder"]),
                            partName: form.getFieldValue(["initData.partName"]),
                            partNumber: form.getFieldValue(["initData.partNumber"]),
                            priority: form.getFieldValue(["initData.priority"]),
                            qtyOfWrong: form.getFieldValue(["initData.qtyOfWrong"]),
                            refNum: form.getFieldValue(["initData.refNum"]),
                            regNum: form.getFieldValue(["initData.regNum"]),
                            releaser: form.getFieldValue(["initData.releaser"]),
                            remark: form.getFieldValue(["initData.remark"]),
                            responsibleDepartment: form.getFieldValue(["initData.responsibleDepartment"]),
                            signOfNextItem: form.getFieldValue(["initData.signOfNextItem"]) == 1 ? "Needed" : "Not needed",
                            state: form.getFieldValue(["initData.state"])
                        }
                    };
                    break;
                case 'Response':
                    obj = {
                        repairData: {
                            team: {
                                name1: form.getFieldValue(["repairData.team.name1"]),
                                name2: form.getFieldValue(["repairData.team.name2"]),
                                name3: form.getFieldValue(["repairData.team.name3"]),
                                name4: form.getFieldValue(["repairData.team.name4"]),
                                name5: form.getFieldValue(["repairData.team.name5"]),
                                name6: form.getFieldValue(["repairData.team.name6"]),
                                name7: form.getFieldValue(["repairData.team.name7"]),

                                rank1: form.getFieldValue(["repairData.team.rank1"]),
                                rank2: form.getFieldValue(["repairData.team.rank2"]),
                                rank3: form.getFieldValue(["repairData.team.rank3"]),
                                rank4: form.getFieldValue(["repairData.team.rank4"]),
                                rank5: form.getFieldValue(["repairData.team.rank5"]),
                                rank6: form.getFieldValue(["repairData.team.rank6"]),
                                rank7: form.getFieldValue(["repairData.team.rank7"]),

                                email1: form.getFieldValue(["repairData.team.email1"]),
                                email2: form.getFieldValue(["repairData.team.email2"]),
                                email3: form.getFieldValue(["repairData.team.email3"]),
                                email4: form.getFieldValue(["repairData.team.email4"]),
                                email5: form.getFieldValue(["repairData.team.email5"]),
                                email6: form.getFieldValue(["repairData.team.email6"]),
                                email7: form.getFieldValue(["repairData.team.email7"])
                            },
                            problem: {
                                name: form.getFieldValue(["repairData.problem.name"]),
                                description: form.getFieldValue(["repairData.problem.description"])
                            },
                            productionData: {
                                who: form.getFieldValue(["repairData.productionData.who"]),
                                what: form.getFieldValue(["repairData.productionData.what"]),
                                when: form.getFieldValue(["repairData.productionData.when"]),
                                where: form.getFieldValue(["repairData.productionData.where"])
                            },
                            immediateActionData: {
                                repairAction: form.getFieldValue(["repairData.immediateActionData.repairAction"]),
                                responsible: form.getFieldValue(["repairData.immediateActionData.responsible"]),
                                deadline: moment(form.getFieldValue(["repairData.immediateActionData.deadline"])).format('YYYY-MM-DD'),
                                handlingOfFoundedPartsAtMtsk: form.getFieldValue(["repairData.immediateActionData.handlingOfFoundedPartsAtMtsk"]),
                            },
                            stockCheckResult: {
                                qtyOfOk: form.getFieldValue(["repairData.stockCheckResult.qtyOfOk"]),
                                qtyOfNg: form.getFieldValue(["repairData.stockCheckResult.qtyOfNg"]),
                                qtyOfSum: form.getFieldValue(["repairData.stockCheckResult.qtyOfSum"]),
                                qtyOfRepaired: form.getFieldValue(["repairData.stockCheckResult.qtyOfRepaired"])
                            }
                        }
                    };
                    break;
                case 'Responsibility':
                    obj = {
                        responsibility: form.getFieldValue(["responsibility"]) == 1 ? 'APPROVE' : 'DENY',
                        responsibilityRemark: form.getFieldValue(["responsibilityRemark"])
                    };
                    break;
                case 'Error reason data':
                    obj = {
                        fiveWhyAnalisysOfErrorCreation: {
                            why1: form.getFieldValue(["fiveWhyAnalisysOfErrorCreation.why1"]),
                            why2: form.getFieldValue(["fiveWhyAnalisysOfErrorCreation.why2"]),
                            why3: form.getFieldValue(["fiveWhyAnalisysOfErrorCreation.why3"]),
                            why4: form.getFieldValue(["fiveWhyAnalisysOfErrorCreation.why4"]),
                            why5: form.getFieldValue(["fiveWhyAnalisysOfErrorCreation.why5"]),
                            rootCauseOfErrorCreation: form.getFieldValue(["fiveWhyAnalisysOfErrorCreation.rootCauseOfErrorCreation"])
                        },
                        fiveWhyAnalisysOfGoingOut: {
                            why1: form.getFieldValue(["fiveWhyAnalisysOfGoingOut.why1"]),
                            why2: form.getFieldValue(["fiveWhyAnalisysOfGoingOut.why2"]),
                            why3: form.getFieldValue(["fiveWhyAnalisysOfGoingOut.why3"]),
                            why4: form.getFieldValue(["fiveWhyAnalisysOfGoingOut.why4"]),
                            why5: form.getFieldValue(["fiveWhyAnalisysOfGoingOut.why5"]),
                            rootCauseOfGoingOut: form.getFieldValue(["fiveWhyAnalisysOfGoingOut.rootCauseOfGoingOut"])
                        },
                        actionsToTerminateCreationOfError: {
                            action1: form.getFieldValue(["actionsToTerminateCreationOfError.action1"]),
                            responsible1: form.getFieldValue(["actionsToTerminateCreationOfError.responsible1"]),
                            deadline1: moment(form.getFieldValue(["actionsToTerminateCreationOfError.deadline1"])).format('YYYY-MM-DD'),

                            action2: form.getFieldValue(["actionsToTerminateCreationOfError.action2"]),
                            responsible2: form.getFieldValue(["actionsToTerminateCreationOfError.responsible2"]),
                            deadline2: moment(form.getFieldValue(["actionsToTerminateCreationOfError.deadline2"])).format('YYYY-MM-DD'),

                            action3: form.getFieldValue(["actionsToTerminateCreationOfError.action3"]),
                            responsible3: form.getFieldValue(["actionsToTerminateCreationOfError.responsible3"]),
                            deadline3: moment(form.getFieldValue(["actionsToTerminateCreationOfError.deadline3"])).format('YYYY-MM-DD'),

                            action4: form.getFieldValue(["actionsToTerminateCreationOfError.action4"]),
                            responsible4: form.getFieldValue(["actionsToTerminateCreationOfError.responsible4"]),
                            deadline4: moment(form.getFieldValue(["actionsToTerminateCreationOfError.deadline4"])).format('YYYY-MM-DD'),

                            action5: form.getFieldValue(["actionsToTerminateCreationOfError.action5"]),
                            responsible5: form.getFieldValue(["actionsToTerminateCreationOfError.responsible5"]),
                            deadline5: moment(form.getFieldValue(["actionsToTerminateCreationOfError.deadline5"])).format('YYYY-MM-DD'),

                            action6: form.getFieldValue(["actionsToTerminateCreationOfError.action6"]),
                            responsible6: form.getFieldValue(["actionsToTerminateCreationOfError.responsible6"]),
                            deadline6: moment(form.getFieldValue(["actionsToTerminateCreationOfError.deadline6"])).format('YYYY-MM-DD'),

                            action7: form.getFieldValue(["actionsToTerminateCreationOfError.action7"]),
                            responsible7: form.getFieldValue(["actionsToTerminateCreationOfError.responsible7"]),
                            deadline7: moment(form.getFieldValue(["actionsToTerminateCreationOfError.deadline7"])).format('YYYY-MM-DD'),
                        },
                        actionsToTerminateGoingOutOfError: {
                            action1: form.getFieldValue(["actionsToTerminateGoingOutOfError.action1"]),
                            responsible1: form.getFieldValue(["actionsToTerminateGoingOutOfError.responsible1"]),
                            deadline1: moment(form.getFieldValue(["actionsToTerminateGoingOutOfError.deadline1"])).format('YYYY-MM-DD'),

                            action2: form.getFieldValue(["actionsToTerminateGoingOutOfError.action2"]),
                            responsible2: form.getFieldValue(["actionsToTerminateGoingOutOfError.responsible2"]),
                            deadline2: moment(form.getFieldValue(["actionsToTerminateGoingOutOfError.deadline2"])).format('YYYY-MM-DD'),

                            action3: form.getFieldValue(["actionsToTerminateGoingOutOfError.action3"]),
                            responsible3: form.getFieldValue(["actionsToTerminateGoingOutOfError.responsible3"]),
                            deadline3: moment(form.getFieldValue(["actionsToTerminateGoingOutOfError.deadline3"])).format('YYYY-MM-DD'),

                            action4: form.getFieldValue(["actionsToTerminateGoingOutOfError.action4"]),
                            responsible4: form.getFieldValue(["actionsToTerminateGoingOutOfError.responsible4"]),
                            deadline4: moment(form.getFieldValue(["actionsToTerminateGoingOutOfError.deadline4"])).format('YYYY-MM-DD'),

                            action5: form.getFieldValue(["actionsToTerminateGoingOutOfError.action5"]),
                            responsible5: form.getFieldValue(["actionsToTerminateGoingOutOfError.responsible5"]),
                            deadline5: moment(form.getFieldValue(["actionsToTerminateGoingOutOfError.deadline5"])).format('YYYY-MM-DD'),

                            action6: form.getFieldValue(["actionsToTerminateGoingOutOfError.action6"]),
                            responsible6: form.getFieldValue(["actionsToTerminateGoingOutOfError.responsible6"]),
                            deadline6: moment(form.getFieldValue(["actionsToTerminateGoingOutOfError.deadline6"])).format('YYYY-MM-DD'),

                            action7: form.getFieldValue(["actionsToTerminateGoingOutOfError.action7"]),
                            responsible7: form.getFieldValue(["actionsToTerminateGoingOutOfError.responsible7"]),
                            deadline7: moment(form.getFieldValue(["actionsToTerminateGoingOutOfError.deadline7"])).format('YYYY-MM-DD'),
                        }
                    };
                    break;
                case 'Control (Receiver)':
                    obj = {
                        resultOfControlReceiver: form.getFieldValue(["resultOfControlReceiver"]) == 1 ? 'APPROVE' : 'DENY',
                        resultOfControlReceiverRemark: form.getFieldValue(["resultOfControlReceiverRemark"])
                    };
                    break;
                case 'Approval (Receiver)':
                    obj = {
                        resultOfApprovalReceiver: form.getFieldValue(["resultOfApprovalReceiver"]) == 1 ? 'APPROVE' : 'DENY',
                        resultOfApprovalReceiverRemark: form.getFieldValue(["resultOfApprovalReceiverRemark"])
                    };
                    break;
                case 'Acceptance':
                    obj = {
                        resultOfAcceptance: form.getFieldValue(["resultOfAcceptance"]) == 1 ? 'APPROVE' : 'DENY',
                        resultOfAcceptanceRemark: form.getFieldValue(["resultOfAcceptanceRemark"])
                    };
                    break;
                case 'Control (Sender)':
                    obj = {
                        resultOfControlSender: form.getFieldValue(["resultOfControlSender"]) == 1 ? 'APPROVE' : 'DENY',
                        resultOfControlSenderRemark: form.getFieldValue(["resultOfControlSenderRemark"])
                    };
                    break;
                case 'Approval (Sender)':
                    obj = {
                        resultOfApprovalSender: form.getFieldValue(["resultOfApprovalSender"]) == 1 ? 'APPROVE' : 'DENY',
                        resultOfApprovalSenderRemark: form.getFieldValue(["resultOfApprovalSenderRemark"])
                    };
                    break;
                case 'Any Error? 1.':
                    obj = {
                        resultOfAnyError1: form.getFieldValue(["resultOfAnyError1"]) == 1 ? 'DENY' : 'APPROVE',
                        resultOfAnyErrorRemark1: form.getFieldValue(["resultOfAnyErrorRemark1"])
                    };
                    break;
                case 'Any Error? 2.':
                    obj = {
                        resultOfAnyError2: form.getFieldValue(["resultOfAnyError2"]) == 1 ? 'DENY' : 'APPROVE',
                        resultOfAnyErrorRemark2: form.getFieldValue(["resultOfAnyErrorRemark2"])
                    };
                    break;
                case 'Any Error? 3.':
                    obj = {
                        resultOfAnyError3: form.getFieldValue(["resultOfAnyError3"]) == 1 ? 'DENY' : 'APPROVE',
                        resultOfAnyErrorRemark3: form.getFieldValue(["resultOfAnyErrorRemark3"])
                    };
                    break;
                case 'Closing':
                case 'Closed':
                    break;
            }

            if(axiosInstance.current != null){
                axiosInstance.current
                    .get(process.env.REACT_APP_API_BASE_URL + '/resource/requests/i-am-the-next?requestId=' + props.requestId)
                    .then(function (response: AxiosResponse<any>) {

                        if(response.data == true){

                            if(!saveWithNextStep){
                                setSaveDataLoading(true);
                            }

                            if(axiosInstance.current != null) {
                                axiosInstance.current.post("/resource/requests/save-request-data?requestId=" + props.requestId,
                                    obj
                                ).then(function (response : AxiosResponse<any>) {


                                    if (fileList.length > 0) {
                                        handleUpload(props.requestId);
                                    }

                                    if(saveWithNextStep){
                                        nextStepMain(response.data.id, response.data.currentState.name);
                                    }else{
                                        message.success(antdContext('Sikeres mentés'));
                                    }

                                }).catch(function (error :any) {
                                    message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                                    console.log(error)
                                }).finally(() => {
                                    if(!saveWithNextStep){
                                        setSaveDataLoading(false);
                                    }
                                });
                            }
                        } else {
                            message.error(antdContext('Nincs jogosultságod folytatni!'));
                        }
                    })
                    .catch(function (error :any) {
                        message.error(antdContext('Nincs jogosultságod folytatni!'));
                    });
            }
        }
    }

    const saveData = (saveWithNextStep: boolean) => {

        validate(saveWithNextStep);
    }

    const nextStepMain = (requestId: any, actStatus: any) => {

        switch(actStatus){
            case 'Start':
                nextStep('APPROVE', requestId, actStatus);
                break;
            case 'Response':
                nextStep('APPROVE', requestId, actStatus);
                break;
            case 'Responsibility':
                nextStep(form.getFieldValue(["responsibility"]) == 1 ? 'APPROVE' : 'DENY', requestId, actStatus);
                break;
            case 'Error reason data':
                nextStep('APPROVE', requestId, actStatus);
                break;
            case 'Control (Receiver)':
                nextStep(form.getFieldValue(["resultOfControlReceiver"]) == 1 ? 'APPROVE' : 'DENY', requestId, actStatus);
                break;
            case 'Approval (Receiver)':
                nextStep(form.getFieldValue(["resultOfApprovalReceiver"]) == 1 ? 'APPROVE' : 'DENY', requestId, actStatus);
                break;
            case 'Acceptance':
                nextStep(form.getFieldValue(["resultOfAcceptance"]) == 1 ? 'APPROVE' : 'DENY', requestId, actStatus);
                break;
            case 'Control (Sender)':
                nextStep(form.getFieldValue(["resultOfControlSender"]) == 1 ? 'APPROVE' : 'DENY', requestId, actStatus);
                break;
            case 'Approval (Sender)':
                nextStep(form.getFieldValue(["resultOfApprovalSender"]) == 1 ? 'APPROVE' : 'DENY', requestId, actStatus);
                break;
            case 'Any Error? 1.':
                nextStep(form.getFieldValue(["resultOfAnyError1"]) == 1 ? 'DENY' : 'APPROVE', requestId, actStatus);
                break;
            case 'Any Error? 2.':
                nextStep(form.getFieldValue(["resultOfAnyError2"]) == 1 ? 'DENY' : 'APPROVE', requestId, actStatus);
                break;
            case 'Any Error? 3.':
                nextStep(form.getFieldValue(["resultOfAnyError3"]) == 1 ? 'DENY' : 'APPROVE', requestId, actStatus);
                break;
            case 'Closing':
                nextStep('APPROVE', requestId, actStatus);
            case 'Closed':
                break;
        }
    }

    const nextStep = (actionType: string, requestId: any, actState: any) => {

        if(axiosInstance.current != null){

            axiosInstance.current
                .get(process.env.REACT_APP_API_BASE_URL + '/resource/requests/i-am-the-next?requestId=' + requestId)
                .then(function (response: AxiosResponse<any>) {

                    if(response.data == true){

                        if(axiosInstance.current != null) {

                            setNextStepLoading(true);
                            axiosInstance.current.post("/resource/requests/complete-by-name-and-action-type?requestId=" + requestId + "&stateName=" + actState + "&actionType=" + actionType,
                                {}
                            ).then(() => {
                                message.success(antdContext('Sikeres léptetés'));
                                setBack(true);

                            }).catch(function (error :any) {
                                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                                console.log(error)
                                setLoading(false);
                            })
                            .finally(() => {
                                setNextStepLoading(false);
                            });

                        }

                    } else {
                        message.error(antdContext('Nincs jogosultságod folytatni!'));
                    }
                })
                .catch(function (error :any) {
                    message.error(antdContext('Nincs jogosultságod folytatni!'));
                });
        }
    }

    useEffect(() => {
        if(errorMessages.length > 0){
            openNotificationWithIcon('error');
        }
    }, [errorMessages]);

    type NotificationType = 'success' | 'info' | 'warning' | 'error';

    const openNotificationWithIcon = (type: NotificationType) => {
        notification[type]({
            message: 'Hibás adatok',
            description: (<>
                {
                    errorMessages.map(
                        x => {
                            return(<div key={x}>{getText(x)}</div>)
                        }
                    )
                }
            </>)
        });
    };

    const getText = (text: String) => {

        switch(text) {
            case 'initData.approvalPerson': return antdContext('Approval person');
            case 'initData.claimType': return antdContext('Claim type');
            case 'initData.controlPerson': return antdContext('Control person');
            case 'initData.dateOfRelease': return antdContext('Release date');
            case 'initData.descriptionOfError': return antdContext('Error description');
            case 'initData.finallyActionDeadline': return antdContext('Final action deadline');
            case 'initData.findingDate': return antdContext('Found date');
            case 'initData.immediateActionDeadline': return antdContext('Immediate action deadline');
            case 'initData.immediateActions': return antdContext('Demanded immediate action');
            case 'initData.location': return antdContext('Found place');
            case 'initData.nameOfError': return antdContext('Error name');
            case 'initData.originOfThePart': return antdContext('Source of spare part');
            case 'initData.otherAction': return antdContext('Other Action');
            case 'initData.otherStakeholder': return antdContext('Other person');
            case 'initData.partName': return antdContext('Sparepart name');
            case 'initData.partNumber': return antdContext('Spare identifier');
            case 'initData.priority': return antdContext('Importance rating');
            case 'initData.qtyOfWrong': return antdContext('Wrong quantity');
            case 'initData.refNum': return antdContext('Reference number');
            case 'initData.regNum': return antdContext('Registration number');
            case 'initData.releaser': return antdContext('Releaser');
            case 'initData.remark': return antdContext('Remark');
            case 'initData.responsibleDepartment': return antdContext('Responsible organisation');
            case 'initData.signOfNextItem': return antdContext('Next item mark');

            case 'repairData.team.name1':
            case 'repairData.team.name2':
            case 'repairData.team.name3':
            case 'repairData.team.name4':
            case 'repairData.team.name5':
            case 'repairData.team.name6':
            case 'repairData.team.name7': return antdContext('name');

            case 'repairData.team.rank1':
            case 'repairData.team.rank2':
            case 'repairData.team.rank3':
            case 'repairData.team.rank4':
            case 'repairData.team.rank5':
            case 'repairData.team.rank6':
            case 'repairData.team.rank7': return antdContext('rank');

            case 'repairData.team.email1':
            case 'repairData.team.email2':
            case 'repairData.team.email3':
            case 'repairData.team.email4':
            case 'repairData.team.email5':
            case 'repairData.team.email6':
            case 'repairData.team.email7': return antdContext('email');

            case 'repairData.problem.name': return antdContext('Problem name');
            case 'repairData.problem.description': return antdContext('Problem details');


            case 'repairData.productionData.who': return antdContext('Who');
            case 'repairData.productionData.what': return antdContext('What');
            case 'repairData.productionData.when': return antdContext('When');
            case 'repairData.productionData.where': return antdContext('Where');

            case 'repairData.immediateActionData.repairAction': return antdContext('Corrective action');
            case 'repairData.immediateActionData.responsible': return antdContext('Responsible');
            case 'repairData.immediateActionData.deadline': return antdContext('Deadline');
            case 'repairData.immediateActionData.handlingOfFoundedPartsAtMtsk': return antdContext('Handling faulty parts');

            case 'repairData.stockCheckResult.qtyOfOk': return antdContext('Count of OK');
            case 'repairData.stockCheckResult.qtyOfNg': return antdContext('Count of NG');
            case 'repairData.stockCheckResult.qtyOfSum': return antdContext('Count of SUM');
            case 'repairData.stockCheckResult.qtyOfRepaired': return antdContext('Count of Repaired');

            case 'responsibility': return antdContext('');
            case 'responsibilityRemark': return antdContext('');

            case 'fiveWhyAnalisysOfErrorCreation.why1':
            case 'fiveWhyAnalisysOfErrorCreation.why2':
            case 'fiveWhyAnalisysOfErrorCreation.why3':
            case 'fiveWhyAnalisysOfErrorCreation.why4':
            case 'fiveWhyAnalisysOfErrorCreation.why5': return antdContext('Why?');
            case 'fiveWhyAnalisysOfErrorCreation.rootCauseOfErrorCreation': return antdContext('');

            case 'fiveWhyAnalisysOfGoingOut.why1':
            case 'fiveWhyAnalisysOfGoingOut.why2':
            case 'fiveWhyAnalisysOfGoingOut.why3':
            case 'fiveWhyAnalisysOfGoingOut.why4':
            case 'fiveWhyAnalisysOfGoingOut.why5': return antdContext('Why?');
            case 'fiveWhyAnalisysOfGoingOut.rootCauseOfGoingOut': return antdContext('');

            case 'actionsToTerminateCreationOfError.action1':
            case 'actionsToTerminateCreationOfError.action2':
            case 'actionsToTerminateCreationOfError.action3':
            case 'actionsToTerminateCreationOfError.action4':
            case 'actionsToTerminateCreationOfError.action5':
            case 'actionsToTerminateCreationOfError.action6':
            case 'actionsToTerminateCreationOfError.action7': return antdContext('Action');

            case 'actionsToTerminateCreationOfError.responsible1':
            case 'actionsToTerminateCreationOfError.responsible2':
            case 'actionsToTerminateCreationOfError.responsible3':
            case 'actionsToTerminateCreationOfError.responsible4':
            case 'actionsToTerminateCreationOfError.responsible5':
            case 'actionsToTerminateCreationOfError.responsible6':
            case 'actionsToTerminateCreationOfError.responsible7': return antdContext('Responsible');

            case 'actionsToTerminateCreationOfError.deadline1':
            case 'actionsToTerminateCreationOfError.deadline2':
            case 'actionsToTerminateCreationOfError.deadline3':
            case 'actionsToTerminateCreationOfError.deadline4':
            case 'actionsToTerminateCreationOfError.deadline5':
            case 'actionsToTerminateCreationOfError.deadline6':
            case 'actionsToTerminateCreationOfError.deadline7': return antdContext('Deadline');



            case 'actionsToTerminateGoingOutOfError.action1':
            case 'actionsToTerminateGoingOutOfError.action2':
            case 'actionsToTerminateGoingOutOfError.action3':
            case 'actionsToTerminateGoingOutOfError.action4':
            case 'actionsToTerminateGoingOutOfError.action5':
            case 'actionsToTerminateGoingOutOfError.action6':
            case 'actionsToTerminateGoingOutOfError.action7': return antdContext('Action');

            case 'actionsToTerminateGoingOutOfError.responsible1':
            case 'actionsToTerminateGoingOutOfError.responsible2':
            case 'actionsToTerminateGoingOutOfError.responsible3':
            case 'actionsToTerminateGoingOutOfError.responsible4':
            case 'actionsToTerminateGoingOutOfError.responsible5':
            case 'actionsToTerminateGoingOutOfError.responsible6':
            case 'actionsToTerminateGoingOutOfError.responsible7': return antdContext('Responsible');

            case 'actionsToTerminateGoingOutOfError.deadline1':
            case 'actionsToTerminateGoingOutOfError.deadline2':
            case 'actionsToTerminateGoingOutOfError.deadline3':
            case 'actionsToTerminateGoingOutOfError.deadline4':
            case 'actionsToTerminateGoingOutOfError.deadline5':
            case 'actionsToTerminateGoingOutOfError.deadline6':
            case 'actionsToTerminateGoingOutOfError.deadline7': return antdContext('Deadline');

            case 'resultOfControlReceiver': return antdContext('Control result');
            case 'resultOfControlReceiverRemark': return antdContext('Control result remark');

            case 'resultOfApprovalReceiver': return antdContext('Approval Receiver');
            case 'resultOfApprovalReceiverRemark': return antdContext('Approval Receiver remark');

            case 'resultOfAcceptance': return antdContext('Acceptance');
            case 'resultOfAcceptanceRemark': return antdContext('Acceptance remark');

            case 'resultOfControlSender': return antdContext('Control Sender');
            case 'resultOfControlSenderRemark': return antdContext('Control Sender remark');

            case 'resultOfApprovalSender': return antdContext('Approval Sender');
            case 'resultOfApprovalSenderRemark': return antdContext('Approval Sender remark');

            case 'resultOfAnyError1': return antdContext('Error 1.');
            case 'resultOfAnyErrorRemark1': return antdContext('Error 1. remark');

            case 'resultOfAnyError2': return antdContext('Error 2.');
            case 'resultOfAnyErrorRemark2': return antdContext('Error 2. remark');

            case 'resultOfAnyError3': return antdContext('Error 3.');
            case 'resultOfAnyErrorRemark3': return antdContext('Error 3. remark');

        }
    }

    const controlResultOnChange = (e: RadioChangeEvent) => {
        console.log('controlresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const acceptResultOnChange = (e: RadioChangeEvent) => {
        console.log('acceptresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const checkResultOnChange = (e: RadioChangeEvent) => {
        console.log('checkresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const approvalResultOnChange = (e: RadioChangeEvent) => {
        console.log('approvalresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const firstErrorResultOnChange = (e: RadioChangeEvent) => {
        console.log('approvalresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const secondErrorResultOnChange = (e: RadioChangeEvent) => {
        console.log('approvalresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const thirdErrorResultOnChange = (e: RadioChangeEvent) => {
        console.log('approvalresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const senderApprovalResultOnChange = (e: RadioChangeEvent) => {
        console.log('approvalresult radio checked', e.target.value);
        setValue(e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    const getWorkflowImg = () => {

        Modal.info({
            closable: true,
            okText: 'Bezárás',
            width: 350,
            title: 'Reklamáció folyamata',
            content: (
                <Image
                    height={400}
                    src={diagram}
                    alt={'Nincs megjeleníthető kép'}
                />
            ),
            onOk() {},
        });
    };

    return(

        back == true ? (
            <ClaimListScreen/>
        ) : (
            <>
            {fileDrawer.component}
            <Row gutter={24}>
                <Col span={24} style={{paddingRight: 50, marginLeft: -20}}>
                    <Steps current={currentState} responsive={true} progressDot={true}>
                        <Step title="10" description={<span style={{fontSize: 12}}><Trans>Releasing</Trans></span>} />
                        <Step title="20" description={<span style={{fontSize: 12}}><Trans>Repair</Trans></span>} />
                        <Step title="30" description={<span style={{fontSize: 12}}><Trans>Rectification</Trans></span>} />
                        <Step title="40" description={<span style={{fontSize: 12}}><Trans>Control</Trans></span>} />
                        <Step title="50" description={<span style={{fontSize: 12}}><Trans>Approval</Trans></span>} />
                        <Step title="60" description={<span style={{fontSize: 12}}><Trans>Acceptance</Trans></span>} />
                        <Step title="70" description={<span style={{fontSize: 12}}><Trans>Control</Trans></span>} />
                        <Step title="80" description={<span style={{fontSize: 12}}><Trans>Approval</Trans></span>} />
                        <Step title="90" description={<span style={{fontSize: 12}}><Trans>Introduction</Trans></span>} />
                        <Step title="100" description={<span style={{fontSize: 12}}><Trans>First Control</Trans></span>} />
                        <Step title="110" description={<span style={{fontSize: 12}}><Trans>Second Control</Trans></span>} />
                        <Step title="120" description={<span style={{fontSize: 12}}><Trans>Third Control</Trans></span>} />
                        <Step title="130" description={<span style={{fontSize: 12}}><Trans>Closure</Trans></span>} />
                    </Steps>
                </Col>
            </Row>
            <br/>
            <Row gutter={24} style={{marginLeft: -10, marginRight: -22, marginBottom: 10}}>
                    <Col span={12}>
                        <Button onClick={ () => setBack(true)} type={"primary"} style={{float: "left", marginRight:10 }}><LeftOutlined /><span><Trans>Back</Trans></span></Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={ () => saveData(true)} loading={nextStepLoading} style={{float: "right", marginRight:10, backgroundColor: "#50a250", color: "white" }}><VerticalLeftOutlined /><span><Trans>Next Step</Trans></span></Button>
                        <Button onClick={ () => saveData(false)} loading={saveDataLoading} style={{float: "right", marginRight:10, backgroundColor: "#44449d", color: "white" }}><SaveOutlined /><span><Trans>Save</Trans></span></Button>
                        <Button onClick={ () => fileDrawer.open("", {requestId: { id: props.requestId}})} style={{float: "right", marginRight:10, backgroundColor: "#9d447d", color: "white" }}><PaperClipOutlined /><span><Trans>Show Files</Trans></span></Button>
                        <Button disabled={closeBtnDisable} onClick={ () => closeClaim(props.requestId)} style={{float: "right", marginRight:10, backgroundColor: "#e61515", color: "white" }}><CloseCircleOutlined /><span><Trans>Closing</Trans></span></Button>
                        <Button onClick={ () => getWorkflowImg()} style={{float: "right", marginRight:10}}><PartitionOutlined /></Button>
                    </Col>
            </Row>
            <Row>
                <Col flex="510px">
                    <Collapse defaultActiveKey={['1']} style={{marginRight: 10}}  >
                        <Panel header="" key="1">
                            <Form id="def"  layout='vertical' form={form}  onFinish={() => alert()}>
                                <Divider><Trans>Defaults</Trans></Divider>
                                <Row gutter={24}>
                                    <Card bordered={false} style={{ width: 240, height: 455 }}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.regNum"
                                                    label={<Trans>Registration number</Trans>}
                                                    children={<Input disabled={!isStartEnable}/>}
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter a registration number</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.refNum"
                                                    label={<Trans>Reference number</Trans>}
                                                    children={<Input {...sharedProps} disabled={!isStartEnable}/>}
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter a reference number</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.originOfThePart"
                                                    initialValue={1}
                                                    label={<Trans>Source of spare part</Trans>}
                                                    children={<Radio.Group disabled={!isStartEnable} onChange={partSourceOnChange} value={value}>
                                                        <Space direction="vertical">
                                                            <Radio value={1} ><Trans>Internal list</Trans></Radio>
                                                            <Radio value={2} ><Trans>Supplier list</Trans></Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.responsibleDepartment"
                                                    label={<Trans>Responsible organisation</Trans>}
                                                    children={
                                                        <Select disabled={!isStartEnable}
                                                                showSearch={true}
                                                                notFoundContent={<Trans id={'No data'}/>}
                                                                style={{ width: '100%' }}
                                                                placeholder={<Trans id={'Responsible organisation'}></Trans>}
                                                                options={mainOptions}
                                                        />
                                                    }
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the responsible organisation</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card bordered={false} style={{ width: 250}}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.dateOfRelease"
                                                    label={<Trans>Release date</Trans>}
                                                    children={<DatePicker placeholder='Dátum' disabled={!isStartEnable}
                                                                          defaultValue={moment()}
                                                                          format={'YYYY.MM.DD'} />}

                                                    rules={[{
                                                        type:"date",
                                                        required: true,
                                                        message: <Trans>Please enter a release date</Trans>,

                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.immediateActionDeadline"
                                                    label={<Trans>Immediate action deadline</Trans>}
                                                    children={<DatePicker placeholder='Dátum' disabled={!isStartEnable}
                                                                          defaultValue={moment()}
                                                                          format={'YYYY.MM.DD'}/>}
                                                    rules={[{
                                                        type:"date",
                                                        required: true,
                                                        message: <Trans>Please enter an immediate action date</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.finallyActionDeadline"
                                                    label={<Trans>Final action deadline</Trans>}
                                                    children={<DatePicker placeholder='Dátum' disabled={!isStartEnable}
                                                                          format={'YYYY.MM.DD'}/>}
                                                    rules={[{
                                                        type:"date",
                                                        required: true,
                                                        message: <Trans>Please enter a final action date</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Row>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
                <Col flex="auto">
                    <Collapse defaultActiveKey={['2']}>
                        <Panel header="" key="2">
                            <Tabs defaultActiveKey="1" type="card">
                                <Tabs.TabPane tab={<Trans>Stakeholders</Trans>} key="1">
                                    <Form id="parti"  layout='vertical' form={form}  onFinish={() => alert()}>
                                        <Row gutter={24}>
                                        <Col span={24}>
                                            <Card title={<Trans>Releaser</Trans>} bordered={false}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="initData.releaser"
                                                            label={<Trans>Releaser</Trans>}
                                                            initialValue={'kiadó'}
                                                            children={<Input disabled={true}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the releaser name</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="initData.controlPerson"
                                                            label={<Trans>Checker person</Trans>}
                                                            initialValue={'ellenőrző'}
                                                            children={<Input disabled={true}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the checker name</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="initData.approvalPerson"
                                                            label={<Trans>Approval person</Trans>}
                                                            initialValue={'jóváhagyó személy'}
                                                            children={<Input disabled={true}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the approval name</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="initData.otherStakeholder"
                                                            label={<Trans>Other person</Trans>}
                                                            children={
                                                                <Select disabled={!isStartEnable}
                                                                        notFoundContent={<Trans id={'No data'}/>}
                                                                        style={{ width: '100%' }}
                                                                        placeholder={<Trans id={'Other users'}></Trans>}
                                                                        options={userOptions}
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                </Row>

                                            </Card>
                                        </Col>
                                    </Row>
                                    </Form>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<Trans>Upload Attachments</Trans>} key="2">
                                    <Form id="attach"  layout='vertical' form={form}  onFinish={() => alert()}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="datafiles"
                                                    children={<Dragger {...uploadProps}>
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>
                                                    </Dragger>
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<Trans>Comment</Trans>} key="3">
                                    <Form id="comm"  layout='vertical' form={form}  onFinish={() => alert()}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.remark"
                                                    label={<Trans>Remark</Trans>}
                                                    children={<TextArea disabled={!isStartEnable} rows={4}/>}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>
                            </Tabs>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Collapse style={{marginTop: 10}} defaultActiveKey={['3']}>
                <Panel header="" key="3">
                    <Tabs defaultActiveKey="1" type="card">
                        <Tabs.TabPane tab={<Trans>Releasing</Trans>} key="1">
                            <Form id="relForm"  layout='vertical' form={form}  onFinish={() => alert()}>
                                <Row gutter={24}>
                                    <Card bordered={false} style={{ width: 475 }}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.claimType"
                                                    label={<Trans>Claim type</Trans>}
                                                    children={
                                                        <Select disabled={!isStartEnable}
                                                                notFoundContent={<Trans id={'No data'}/>}
                                                                style={{ width: '100%' }}
                                                                placeholder={<Trans id={'claim type'}></Trans>}
                                                                options={claimTypeOptions}
                                                        />
                                                    }
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the claim type</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.partNumber"
                                                    label={<Trans>Spare identifier</Trans>}
                                                    children={
                                                        <Select
                                                            allowClear
                                                            disabled={!isStartEnable}
                                                            showSearch={true}
                                                            style={{ width: '100%' }}
                                                            notFoundContent={<Trans id={'No data'}/>}
                                                            placeholder={<Trans id={'Spare identifier'}></Trans>}
                                                            onSearch={value => setItemNoSearch(value)}
                                                            onChange={value => handleOutDeviceToOnChange(value)}
                                                            options={itemNo}
                                                        />

                                                    }
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the spare identifier</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.partName"
                                                    label={<Trans>Sparepart name</Trans>}
                                                    initialValue={'alkatrész neve'}
                                                    children={<Input disabled={true}/>}
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the spare name</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.location"
                                                    label={<Trans>Found place</Trans>}
                                                    children={<Input disabled={!isStartEnable} {...sharedProps}/>}
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the found place name</Trans>,
                                                        validator: () => {

                                                            let v:string = form.getFieldValue('initData.location');

                                                            if (v == undefined || v.length <= 0) {
                                                                inputRef.current!.focus({});
                                                                return Promise.reject('');
                                                            } else {
                                                                return Promise.resolve('');
                                                            }
                                                        }
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.findingDate"
                                                    label={<Trans>Found date</Trans>}
                                                    children={<DatePicker placeholder='Dátum' disabled={!isStartEnable}
                                                                          format={'YYYY.MM.DD'}/>}
                                                    rules={[{
                                                        type:"date",
                                                        required: true,
                                                        message: <Trans>Please enter a found date</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card bordered={false} style={{ width: 475 }}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.signOfNextItem"
                                                    initialValue={1}
                                                    label={<Trans>Next item mark</Trans>}
                                                    children={<Radio.Group disabled={!isStartEnable} onChange={nextItemMarkOnChange} value={value}>
                                                        <Space direction="vertical">
                                                            <Radio value={1}><Trans>Needed</Trans></Radio>
                                                            <Radio value={2}><Trans>Not needed</Trans></Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.immediateActions"
                                                    label={<Trans>Demanded immediate action</Trans>}
                                                    children={
                                                        <Select disabled={!isStartEnable}
                                                                notFoundContent={<Trans id={'No data'}/>}
                                                                style={{ width: '100%' }}
                                                                placeholder={<Trans id={'Demanded immediate action'}></Trans>}
                                                                options={demandedImmediateActionOptions}
                                                                onChange={handleDemandActionChange}
                                                        />
                                                    }
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the demanded immediate action</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="otherAction"
                                                    label={<Trans>Other Action</Trans>}
                                                    children={<TextArea disabled={!isStartEnable || !isOtherEnable} rows={4}/>}
                                                    rules={[{
                                                        type:"string",
                                                        required: isOtherEnable,
                                                        message: <Trans>Please enter an other action</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.priority"
                                                    label={<Trans>Importance rating</Trans>}
                                                    children={
                                                        <Select disabled={!isStartEnable}
                                                                notFoundContent={<Trans id={'No data'}/>}
                                                                style={{ width: '100%' }}
                                                                placeholder={<Trans id={'Importance rating'}></Trans>}
                                                                options={importanceRatingOptions}
                                                        />
                                                    }
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the importance rating</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card bordered={false} style={{ width: 475 }}>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.nameOfError"
                                                    label={<Trans>Error name</Trans>}
                                                    children={<Input {...sharedProps} disabled={!isStartEnable} />}
                                                    rules={[{
                                                        type:"string",
                                                        required: true,
                                                        message: <Trans>Please enter the error name</Trans>,
                                                        validator: () => {

                                                            let v:string = form.getFieldValue('initData.nameOfError');

                                                            if (v == undefined || v.length <= 0) {
                                                                inputRef.current!.focus({});
                                                                return Promise.reject('');
                                                            } else {
                                                                return Promise.resolve('');
                                                            }
                                                        }
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.qtyOfWrong"
                                                    label={<Trans>Wrong quantity</Trans>}
                                                    children={<InputNumber min={0} disabled={!isStartEnable} onChange={wrongQuantityOnChange}/>}
                                                    rules={[{
                                                        type:"number",
                                                        required: true,
                                                        message: <Trans>Please enter the wrong quantity</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.qtyOfFinallyWrong"
                                                    label={<Trans>Finally Wrong quantity</Trans>}
                                                    children={<InputNumber min={0} disabled={true} onChange={finallyWrongQuantityOnChange}/>}
                                                    rules={[{
                                                        type:"number",
                                                        required: false,
                                                        message: <Trans>Please enter the finally wrong quantity</Trans>
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="initData.descriptionOfError"
                                                    label={<Trans>Error description</Trans>}
                                                    children={<TextArea disabled={!isStartEnable} rows={4}/>
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Row>
                            </Form>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<Trans>Response</Trans>} key="2">
                            <Tabs defaultActiveKey="1" type="card">
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Team</Trans></div>} key="1">
                                    <Row gutter={24}>
                                        <Card style={{ width: 850, margin: 10 }} bordered={false}>
                                            <Form id="teamF"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={3} style={{paddingTop: 35}}><span>1.</span></Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.name1"
                                                            label={<Trans>name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a group name</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.rank1"
                                                            label={<Trans>rank</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a group rank</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.email1"
                                                            label={<Trans>email</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"email",
                                                                required: true,
                                                                message: <Trans>Please enter a group email</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={3}  style={{paddingTop: 35}}><span>2.</span></Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.name2"
                                                            label={<Trans>name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable} type={"text"} onChange={value => groupName2Change(value)}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter a group name</Trans>,

                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.rank2"
                                                            label={<Trans>rank</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGroupName2,
                                                                message: <Trans>Please enter a group rank</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.email2"
                                                            label={<Trans>email</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"email",
                                                                required: !isEmptyGroupName2,
                                                                message: <Trans>Please enter a group email</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={3}  style={{paddingTop: 35}}><span>3.</span></Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.name3"
                                                            label={<Trans>name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable} type={"text"} onChange={value => groupName3Change(value)}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter a group name</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.rank3"
                                                            label={<Trans>rank</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGroupName3,
                                                                message: <Trans>Please enter a group rank</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.email3"
                                                            label={<Trans>email</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"email",
                                                                required: !isEmptyGroupName3,
                                                                message: <Trans>Please enter a group email</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={3}  style={{paddingTop: 35}}><span>4.</span></Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.name4"
                                                            label={<Trans>name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable} type={"text"} onChange={value => groupName4Change(value)}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter a group name</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.rank4"
                                                            label={<Trans>rank</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGroupName4,
                                                                message: <Trans>Please enter a group rank</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.email4"
                                                            label={<Trans>email</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"email",
                                                                required: !isEmptyGroupName4,
                                                                message: <Trans>Please enter a group email</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={3}  style={{paddingTop: 35}}><span>5.</span></Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.name5"
                                                            label={<Trans>name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable} type={"text"} onChange={value => groupName5Change(value)}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter a group name</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.rank5"
                                                            label={<Trans>rank</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGroupName5,
                                                                message: <Trans>Please enter a group rank</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.email5"
                                                            label={<Trans>email</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"email",
                                                                required: !isEmptyGroupName5,
                                                                message: <Trans>Please enter a group email</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={3}  style={{paddingTop: 35}}><span>6.</span></Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.name6"
                                                            label={<Trans>name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable} type={"text"} onChange={value => groupName6Change(value)}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter a group name</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.rank6"
                                                            label={<Trans>rank</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGroupName6,
                                                                message: <Trans>Please enter a group rank</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.email6"
                                                            label={<Trans>email</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"email",
                                                                required: !isEmptyGroupName6,
                                                                message: <Trans>Please enter a group email</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={3}  style={{paddingTop: 35}}><span>7.</span></Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.name7"
                                                            label={<Trans>name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable} type={"text"} onChange={value => groupName7Change(value)}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter a group name</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.rank7"
                                                            label={<Trans>rank</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGroupName7,
                                                                message: <Trans>Please enter a group rank</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            name="repairData.team.email7"
                                                            label={<Trans>email</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"email",
                                                                required: !isEmptyGroupName7,
                                                                message: <Trans>Please enter a group email</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Problem</Trans></div>} key="2">
                                    <Row gutter={24}>
                                        <Card style={{ width: 600, margin: 10 }} title={<Trans>Problem</Trans>}>
                                            <Form id="probl"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.problem.name"
                                                            label={<Trans>Problem name</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a problem name</Trans>,
                                                                validator: () => {

                                                                    let v:string = form.getFieldValue('repairData.problem.name');

                                                                    if (v == undefined || v.length <= 0) {
                                                                        inputRef.current!.focus({});
                                                                        return Promise.reject('');
                                                                    } else {
                                                                        return Promise.resolve('');
                                                                    }
                                                                }
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.problem.description"
                                                            label={<Trans>Problem details</Trans>}
                                                            children={<TextArea {...sharedProps} disabled={!isResponseEnable} rows={20}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a problem details</Trans>,
                                                                validator: () => {

                                                                    let v:string = form.getFieldValue('repairData.problem.description');

                                                                    if (v == undefined || v.length <= 0) {
                                                                        inputRef.current!.focus({});
                                                                        return Promise.reject('');
                                                                    } else {
                                                                        return Promise.resolve('');
                                                                    }
                                                                }
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                        <Card style={{ width: 600, margin: 10 }} title={<Trans>Details of Production</Trans>}>
                                            <Form id="problDet"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.productionData.who"
                                                            label={<Trans>Who</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a factory data who</Trans>,
                                                                validator: () => {

                                                                    let v:string = form.getFieldValue('repairData.productionData.who');

                                                                    if (v == undefined || v.length <= 0) {
                                                                        inputRef.current!.focus({});
                                                                        return Promise.reject('');
                                                                    } else {
                                                                        return Promise.resolve('');
                                                                    }
                                                                }
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.productionData.what"
                                                            label={<Trans>What</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a factory data what</Trans>,
                                                                validator: () => {

                                                                    let v:string = form.getFieldValue('repairData.productionData.what');

                                                                    if (v == undefined || v.length <= 0) {
                                                                        inputRef.current!.focus({});
                                                                        return Promise.reject('');
                                                                    } else {
                                                                        return Promise.resolve('');
                                                                    }
                                                                }
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.productionData.when"
                                                            label={<Trans>When</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a factory data when</Trans>,
                                                                validator: () => {

                                                                    let v:string = form.getFieldValue('repairData.productionData.when');

                                                                    if (v == undefined || v.length <= 0) {
                                                                        inputRef.current!.focus({});
                                                                        return Promise.reject('');
                                                                    } else {
                                                                        return Promise.resolve('');
                                                                    }
                                                                }
                                                            }]}
                                                        />

                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.productionData.where"
                                                            label={<Trans>Where</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a factory data where</Trans>,
                                                                validator: () => {

                                                                    let v:string = form.getFieldValue('repairData.productionData.where');

                                                                    if (v == undefined || v.length <= 0) {
                                                                        inputRef.current!.focus({});
                                                                        return Promise.reject('');
                                                                    } else {
                                                                        return Promise.resolve('');
                                                                    }
                                                                }
                                                            }]}
                                                        />

                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Immediately Action</Trans></div>} key="3">
                                    <Row gutter={24}>
                                        <Card style={{ width: 400, margin: 10 }} title={<Trans>Immediate Measures</Trans>}>
                                            <Form id="immed"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.immediateActionData.repairAction"
                                                            label={<Trans>Corrective action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type: "string",
                                                                required: true,
                                                                message: <Trans>Please enter a immediate action
                                                                    correct</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.immediateActionData.responsible"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isResponseEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter a immediateaction responsible</Trans>,
                                                                validator: () => {

                                                                    let v:string = form.getFieldValue('repairData.immediateActionData.responsible');

                                                                    if (v == undefined || v.length <= 0) {
                                                                        inputRef.current!.focus({});
                                                                        return Promise.reject('');
                                                                    } else {
                                                                        return Promise.resolve('');
                                                                    }
                                                                }
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.immediateActionData.deadline"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isResponseEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: true,
                                                                message: <Trans>Please enter a immediate action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.immediateActionData.handlingOfFoundedPartsAtMtsk"
                                                            label={<Trans>Handling faulty parts</Trans>}
                                                            children={
                                                                <Select disabled={!isResponseEnable}
                                                                        notFoundContent={<Trans id={'No data'}/>}
                                                                        style={{ width: '100%' }}
                                                                        placeholder={<Trans id={'Handling faulty parts'}></Trans>}
                                                                        options={handlingFaultyPartsOptions}
                                                                />
                                                            }
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the handling faulty parts</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                        <Card style={{ width: 400, margin: 10 }} title={<Trans>Result of Stock Check</Trans>}>
                                            <Form id="resOfStck"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.stockCheckResult.qtyOfOk"
                                                            label={<Trans>Count of OK</Trans>}
                                                            children={<InputNumber min={0} disabled={!isResponseEnable || !needToCheck}/>}
                                                            rules={[{
                                                                type:"number",
                                                                required: needToCheck,
                                                                message: <Trans>Please fill number of OK</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.stockCheckResult.qtyOfNg"
                                                            label={<Trans>Count of NG</Trans>}
                                                            children={<InputNumber min={0} disabled={!isResponseEnable || !needToCheck}/>}
                                                            rules={[{
                                                                type:"number",
                                                                required: needToCheck,
                                                                message: <Trans>Please fill number of NG</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.stockCheckResult.qtyOfSum"
                                                            label={<Trans>Count of SUM</Trans>}
                                                            children={<InputNumber min={0} disabled={!isResponseEnable || !needToCheck}/>}
                                                            rules={[{
                                                                type:"number",
                                                                required: needToCheck,
                                                                message: <Trans>Please fill number of SUM</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="repairData.stockCheckResult.qtyOfRepaired"
                                                            label={<Trans>Count of Repaired</Trans>}
                                                            children={<InputNumber min={0} disabled={!isResponseEnable || !needToCheck}/>}
                                                            rules={[{
                                                                type:"number",
                                                                required: needToCheck,
                                                                message: <Trans>Please fill number of Repaired</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Responsibility</Trans></div>} key="4">
                                    <Row gutter={24}>

                                        <Card style={{ width: 400, margin: 10 }} title={<Trans>Responsibility</Trans>}>
                                            <Form id="respF"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="responsibility"
                                                            initialValue={1}

                                                            children={<Radio.Group disabled={!isResponsibilityEnable} onChange={responsibilityOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>Yes</Trans></Radio>
                                                                    <Radio value={2}><Trans>No</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="responsibilityRemark"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isResponsibilityEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Error Reason</Trans></div>} key="5">
                                    <Row gutter={24}>
                                        <Card style={{ width: 400, margin: 10 }} title={<Trans>5 why analysis about creation</Trans>}>
                                            <Form id="creationFiveWhy"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfErrorCreation.why1"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfErrorCreation.why2"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfErrorCreation.why3"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfErrorCreation.why4"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfErrorCreation.why5"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfErrorCreation.rootCauseOfErrorCreation"
                                                            label={<Trans>Root cause of the error</Trans>}
                                                            children={<TextArea {...sharedProps} disabled={!isErrorReasonDataEnable} rows={4}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                        <Card style={{ width: 400, margin: 10 }} title={<Trans>5 why analysis about got out</Trans>}>
                                            <Form id="outgoingFiveWhy"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfGoingOut.why1"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfGoingOut.why2"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfGoingOut.why3"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfGoingOut.why4"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfGoingOut.why5"
                                                            label={<Trans>Why?</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="fiveWhyAnalisysOfGoingOut.rootCauseOfGoingOut"
                                                            label={<Trans>Root cause of the exit</Trans>}
                                                            children={<TextArea {...sharedProps} disabled={!isErrorReasonDataEnable} rows={4}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Finally Action</Trans></div>} key="6">
                                    <Row>
                                        <Card style={{ width: 700, margin: 10 }} title={<Trans>Actions to avoid the occurrence of the error</Trans>}>
                                            <Form id="occurance"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>1.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.action1"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.responsible1"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.deadline1"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: true,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>2.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.action2"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => occurenceErrorAction2Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.responsible2"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyOccurenceErrorAction2,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.deadline2"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyOccurenceErrorAction2,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>3.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.action3"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => occurenceErrorAction3Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.responsible3"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyOccurenceErrorAction3,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.deadline3"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyOccurenceErrorAction3,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>4.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.action4"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => occurenceErrorAction4Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.responsible4"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyOccurenceErrorAction4,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.deadline4"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyOccurenceErrorAction4,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>5.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.action5"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => occurenceErrorAction5Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.responsible5"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyOccurenceErrorAction5,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.deadline5"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyOccurenceErrorAction5,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>6.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.action6"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => occurenceErrorAction6Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.responsible6"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyOccurenceErrorAction6,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.deadline6"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyOccurenceErrorAction6,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>7.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.action7"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => occurenceErrorAction7Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.responsible7"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyOccurenceErrorAction7,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateCreationOfError.deadline7"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyOccurenceErrorAction7,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                        <Card style={{ width: 700, margin: 10 }} title={<Trans>Actions to prevent the error going out</Trans>}>
                                            <Form id="goingout"  layout='vertical' form={form}  onFinish={() => alert()}>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>1.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.action1"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.responsible1"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: true,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.deadline1"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: true,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>2.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.action2"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => goingOutAction2Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.responsible2"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGoingOutAction2,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.deadline2"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyGoingOutAction2,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>3.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.action3"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => goingOutAction3Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.responsible3"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGoingOutAction3,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.deadline3"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyGoingOutAction3,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>4.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.action4"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => goingOutAction4Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.responsible4"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGoingOutAction4,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.deadline4"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyGoingOutAction4,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>5.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.action5"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => goingOutAction5Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.responsible5"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGoingOutAction5,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.deadline5"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyGoingOutAction5,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>6.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.action6"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => goingOutAction6Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.responsible6"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGoingOutAction6,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.deadline6"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyGoingOutAction6,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col style={{paddingTop: 35}}><span>7.</span></Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.action7"
                                                            label={<Trans>Action</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} onChange={value => goingOutAction7Change(value)} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: false,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.responsible7"
                                                            label={<Trans>Responsible</Trans>}
                                                            children={<Input {...sharedProps} disabled={!isErrorReasonDataEnable} />}
                                                            rules={[{
                                                                type:"string",
                                                                required: !isEmptyGoingOutAction7,
                                                                message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                                            }]}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="actionsToTerminateGoingOutOfError.deadline7"
                                                            label={<Trans>Deadline</Trans>}
                                                            children={<DatePicker placeholder='Dátum' disabled={!isErrorReasonDataEnable}
                                                                                  format={'YYYY.MM.DD'}/>}
                                                            rules={[{
                                                                type:"date",
                                                                required: !isEmptyGoingOutAction7,
                                                                message: <Trans>Please enter a main reason action deadline</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Control Receiver</Trans></div>} key="7">
                                    <Form id="controlAcceptor"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Control result</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfControlReceiver"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isControlReceiverEnable} onChange={controlResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfControlReceiverRemark"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isControlReceiverEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<div className={"receiverTab"}><Trans>Approval Receiver</Trans></div>} key="8">
                                    <Form id="approvalReceiver"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Approval Receiver</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfApprovalReceiver"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isApprovalReceiverEnable} onChange={approvalResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfApprovalReceiverRemark"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isApprovalReceiverEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<Trans>Acceptance</Trans>} key="9">
                                    <Form id="acceptance"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Acceptance</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAcceptance"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isAcceptanceEnable} onChange={acceptResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAcceptanceRemark"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isAcceptanceEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<Trans>Control Sender</Trans>} key="10">
                                    <Form id="controlSender"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Control Sender</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfControlSender"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isControlSenderEnable} onChange={checkResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfControlSenderRemark"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isControlSenderEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<Trans>Approval Sender</Trans>} key="11">
                                    <Form id="approvalSender"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Approval Sender</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfApprovalSender"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isApprovalSenderEnable} onChange={senderApprovalResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfApprovalSenderRemark"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isApprovalSenderEnable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<Trans>Error 1.</Trans>} key="12">
                                    <Form id="firstError"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Error 1.</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAnyError1"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isAnyError1Enable} onChange={firstErrorResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAnyErrorRemark1"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isAnyError1Enable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<Trans>Error 2.</Trans>} key="13">
                                    <Form id="secondError"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Error 2.</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAnyError2"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isAnyError2Enable} onChange={secondErrorResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAnyErrorRemark2"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isAnyError2Enable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<Trans>Error 3.</Trans>} key="14">
                                    <Form id="thirdError"  layout='vertical' form={form}>
                                        <Row gutter={24}>
                                            <Card style={{ width: 400, margin: 10 }} title={<Trans>Error 3.</Trans>}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAnyError3"
                                                            initialValue={1}
                                                            children={<Radio.Group disabled={!isAnyError3Enable} onChange={thirdErrorResultOnChange} value={value}>
                                                                <Space direction="vertical">
                                                                    <Radio value={1}><Trans>OK</Trans></Radio>
                                                                    <Radio value={2}><Trans>Not ok</Trans></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="resultOfAnyErrorRemark3"
                                                            label={<Trans>Justification</Trans>}
                                                            children={<TextArea rows={4} disabled={!isAnyError3Enable}/>}
                                                            rules={[{
                                                                type:"string",
                                                                required: justificationRequired,
                                                                message: <Trans>Please enter the justification</Trans>
                                                            }]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>

                            </Tabs>
                        </Tabs.TabPane>
                    </Tabs>
                </Panel>
            </Collapse>
        </>
        )
    );

}
export default TestForm;
